<template>
    <div class="tracker-content">
        <div class="tracker-content__header">
            <a :href="trackerLink"
               class="tracker-content__header--left">
                <img loading="lazy"
                     class="tracker-content__logo"
                     width="32"
                     height="32"
                     :src="asset_cdn(logo, 50)"
                     :alt="`${title}`"
                     :title="`${title}`">
                <div class="tracker-content__text">
                    <h5 class="tracker-content__title">
                        {{ title }}
                    </h5>
                    <p v-if="!isExchange"
                       class="tracker-content__subtitle">
                        {{ ticker }}
                    </p>
                </div>
            </a>
            <div class="tracker-content__header--right">
                <span class="tracker-content__change"
                      :class="{ 'tracker-content__change--green': dailyChange > 0 }">
                    {{ dailyChange.toFixed(2) }}%
                </span>
            </div>
        </div>
        <div class="tracker-content__body">
            <TrackerEmbedEntry title="Volume"
                               badge="24h"
                               :value="currencyFormat(volume, true)">
                <template #tooltipContent>
                    <!-- eslint-disable -->
                    <Translate translationKey="tooltips.volumeTooltip"
                               fallback="It is a quantitative metric calculating how many individual units of specific cryptocurrency coins/tokens were traded (bought & sold) within the last 24 hours. It\'s a direct cryptocurrency\'s supply & demand indicator, and is purely related to its market price." />
                    <!-- eslint-enable -->
                    <br>
                    <br>
                    <a :href="route('crypto-book-glossary') + '/what-is-volume'"
                       class="tracker-content__entry-tooltip">
                        <Translate translationKey="tooltips.learnThisMetric"
                                   fallback="Learn this metric" />
                    </a>
                </template>
            </TrackerEmbedEntry>
            <TrackerEmbedEntry v-if="!isExchange"
                               class="tracker-content__entry"
                               title="Market Cap"
                               :value="currencyFormat(marketCap, true)">
                <template #tooltipContent>
                    <!-- eslint-disable -->
                    <Translate translationKey="tooltips.marketCapTooltip"
                               fallback="Market Capitalization is the overall value of all coins/tokens that have been mined or issued until now and are in circulation (not locked). It\'s similar to the stock markets\' Free-Float Capitalization." />
                    <!-- eslint-enable -->
                    <br>
                    <br>
                    <Translate translationKey="tooltips.marketCapTooltip1"
                               fallback="Market Capitalization = Circulating Supply x Current Price." />
                    <br>
                    <br>
                    <a :href="route('crypto-book-glossary') + '/what-is-market-capitalization-market-cap-mcap'"
                       class="tracker-content__entry-tooltip">
                        <Translate translationKey="tooltips.learnThisMetric"
                                   fallback="Learn this metric" />
                    </a>
                </template>
            </TrackerEmbedEntry>
        </div>
        <div class="tracker-content__footer">
            <a :href="buttonLink"
               target="_blank"
               rel="nofollow noindex noopener">
                <button class="tracker-content__button btn btn-buy">
                    <template v-if="!isExchange">
                        <Translate translationKey="cryptocurrencies.buyButton"
                                   :fallback="`Buy ${ticker} Now`"
                                   :replacements="{ currency_code: ticker }" />
                        <i class="fa-solid fa-arrow-right" />
                    </template>
                    <template v-else>
                        <Translate translationKey="general.visitSite"
                                   fallback="Visit Site"/>
                    </template>
                </button>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { TrackerProps } from '@/js/components/Tippy/TrackerEmbedTippy/types';
    import { asset_cdn, currencyFormat, route } from '@/js/helpers/helpers';
    import TrackerEmbedEntry from '@/js/components/Tippy/TrackerEmbedTippy/components/TrackerEmbedEntry.vue';
    import Translate from '@/js/helpers/Translate.vue';
    import { BINANCE_JOIN_LINK } from '@/js/quests/dashboard/constants';

    const props = defineProps<Omit<TrackerProps, 'disabled'>>();

    const EXCHANGE_BUTTON_LINK = `https://www.bitdegree.org/crypto/goon/${props.slug}`;
    const buttonLink = props.isExchange ? EXCHANGE_BUTTON_LINK : BINANCE_JOIN_LINK;
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/sass/partials/mixins";

.tracker-content {
    display: flex;
    min-width: 250px;
    flex-direction: column;
    padding: 16px 8px;
    gap: 12px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;

        .tracker-content__header--left {
            display: flex !important;
            align-items: center !important;
            background-image: none !important;
            font-weight: 500 !important;

            &::before {
                content: unset;
            }

            &:link {
                text-decoration: none;
            }

            &:hover {
                .tracker-content__text {
                    opacity: 0.7;
                }
            }

            .tracker-content__logo {
                padding: 1px;
                border: 1px solid $border;
                border-radius: 100%;
                margin: 0;
            }

            .tracker-content__text {
                display: flex;
                flex-direction: column;
                margin-left: 6px;
                font-weight: 700;
                transition: opacity 150ms ease-in-out;

                .tracker-content__title {
                    @include one-line-clamp();
                    max-width: 200px;
                    margin-bottom: 0;
                    color: $baseBlack;
                    font-size: 16px;
                }

                .tracker-content__subtitle {
                    margin-bottom: 0;
                    color: $pill-color;
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }

        &--right {
            .tracker-content__change {
                color: $error500;
                font-size: 14px;
                font-weight: 600;

                &--green {
                    color: $primary500;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__entry {
        padding-top: 8px;
        border-top: 1px solid $star-rating-inactive;
        margin-top: 8px;
    }

    .tracker-content__entry-tooltip {
        background-image: none;
        font-weight: 500;

        &::before {
            content: unset;
        }

        &:link {
            text-decoration: none;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 4px;

        a {
            background-image: none !important;

            &::before {
                content: unset !important;
            }

            &:link {
                text-decoration: none !important;
            }
        }
    }

    &__button {
        padding: 5px 10px;

        i {
            color: $baseWhite !important;
        }
    }
}
</style>
